import { Alert, Button, Carousel, Collapse, Dropdown, Modal, Offcanvas, Popover, ScrollSpy, Tab, Tooltip, Toast } from 'bootstrap';
document.addEventListener("DOMContentLoaded", function() {
    let toastElems = document.querySelectorAll('.toast');
    function launchProgressBar(progressBar) {
        if(progressBar){
            progressBar.style.width = "100%";
            setTimeout(() => {
                progressBar.style.width = "0%";
            }, 5);
        }
    }
    function resetProgressBar(progressBar) {
        if(progressBar){
            let duration = progressBar.style.transitionDuration;
            progressBar.style.transitionDuration = '0s';
            progressBar.style.width = "100%";
            setTimeout(() => {
                progressBar.style.transitionDuration = duration;
            }, 5);
        }
    }
    toastElems.forEach((toastElem) => {
        let toast = Toast.getOrCreateInstance(toastElem);
        toastElem.addEventListener('shown.bs.toast', (e) => {
            launchProgressBar(e.target.querySelector('.progress-bar'));
        });
        toastElem.addEventListener('pointerenter', (e) => {
            resetProgressBar(e.target.querySelector('.progress-bar'));
        });
        toastElem.addEventListener('pointerleave', (e) => {
            launchProgressBar(e.target.querySelector('.progress-bar'));
        });
        toast.show();
    });
});
