import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

let tooltipElements = document.querySelectorAll("[data-bs-toggle='tooltip']");


tooltipElements.forEach((el) => {
    new bootstrap.Tooltip(el);
});
